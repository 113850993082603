var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{attrs:{"id":"dayspan"}},[_c('ds-calendar-app',{ref:"cal",attrs:{"calendar":_vm.calendar,"read-only":true,"allowsAddToday":false,"use-drawer":false},on:{"change":function (){}},scopedSlots:_vm._u([{key:"eventTitle",fn:function(ref){
var details = ref.details;
return [_c('div',{attrs:{"id":details.elementId}},[_c('strong',{staticClass:"ds-ev-title",class:details.class,attrs:{"bx-attr":("task-data" + (details.id))},on:{"click":function($event){_vm.viewPopup(details.id, $event); _vm.removeTooltip(("tooltip_" + (details.elementId)))},"mouseenter":function($event){return _vm.tooltip(details.elementId, details.title)},"mouseleave":function($event){return _vm.removeTooltip(("tooltip_" + (details.elementId)))}}},[_vm._v(_vm._s(details.title))])])]}},{key:"eventTimeTitle",fn:function(ref){
var details = ref.details;
return [_c('div',{attrs:{"id":details.elementId}},[_c('strong',{staticClass:"ds-ev-title",class:details.class,attrs:{"bx-attr":("week-data" + (details.id))},on:{"click":function($event){_vm.viewPopup(details.id, $event); _vm.removeTooltip(("tooltip_" + (details.elementId)))},"mouseenter":function($event){return _vm.tooltip(details.elementId, details.title)},"mouseleave":function($event){return _vm.removeTooltip(("tooltip_" + (details.elementId)))}}},[_vm._v(_vm._s(details.title))])])]}},{key:"eventPopover",fn:function(slotData){return [_c('ds-calendar-event-popover',_vm._b({attrs:{"readOnly":true,"allowEditOnReadonly":false}},'ds-calendar-event-popover',slotData,false))]}},{key:"eventCreatePopover",fn:function(ref){
var placeholder = ref.placeholder;
var calendar = ref.calendar;
var close = ref.close;
return [_c('ds-calendar-event-create-popover',{attrs:{"calendar-event":placeholder,"calendar":calendar,"close":_vm.$refs.cal.$refs.calendar.clearPlaceholder},on:{"create-edit":_vm.$refs.cal.editPlaceholder}})]}}])},[_c('template',{slot:"summary"},[_c('div',{staticClass:"date-summary"},[_c('p',{attrs:{"bx-attr":"customDate-text"}},[_vm._v(_vm._s(_vm.customDate))])])]),_c('template',{slot:"prev"},[_c('div',{staticClass:"back",attrs:{"bx-attr":"back-btn","id":"backMonth"},on:{"click":_vm.prev}},[_c('img',{attrs:{"src":require("./../../../../assets/images/icon-purple/menu-pre.svg"),"alt":"back"}})])]),_c('template',{slot:"next"},[_c('div',{staticClass:"next",attrs:{"bx-attr":"next-btn","id":"nextMonth"},on:{"click":_vm.next}},[_c('img',{attrs:{"src":require("./../../../../assets/images/icon-purple/menu-back.svg"),"alt":"next"}})])]),_c('template',{slot:"menuRight"},[_c('div',{staticClass:"toogle-view",attrs:{"id":"monthBtn"}},[_c('div',{class:['week-view', _vm.view === 'weeks' && 'view-selected-left'],attrs:{"bx-attr":"weeks-btn"},on:{"click":function($event){return _vm.changeView('weeks')}}},[_vm._v("Week")]),_c('div',{class:['month-view', _vm.view === 'months' && 'view-selected-right'],attrs:{"bx-attr":"months-btn"},on:{"click":function($event){return _vm.changeView('months')}}},[_vm._v("Month")])])])],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }