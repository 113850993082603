import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import { Calendar, Units } from 'dayspan';
import { IEvents, ITaskDto } from '@/services/program/plannerService';
import APP_CONST from '@/constants/AppConst';
import APP_UTILITIES from '@/utilities/commonFunctions';
import plannerStore from '@/store/modules/plannerStore'

@Component({
  name: 'calender'
})
export default class CalenderTaskItem extends Vue {
  storeKey: string = 'dayspanState';
  calendar = Calendar.months();
  readOnly = true;
  defaultEvents: IEvents[] = [];
  mountedref = false;
  currentDate = APP_UTILITIES.getCurrentDate();
  customDate = '';

  $refs!: {
    cal: HTMLFormElement
  }

  @Prop()
  tasks!: [];

  get view() {
    return plannerStore.getDurationView;
  }

  mounted() {
    let view: any = this.view === 'weeks' ? "weeks" : "months";
    this.changeView(view);
    if (this.tasks.length > 0) {
      this.calenderStructure();
    } else {
      this.createTitleDate();
    }
    this.mountedref = true;
    this.getClassList();
  }

  destroyed() {
    plannerStore.changeDurationView("months");
  }

  @Watch('tasks', { deep: true })
  updateView() {
    if (this.mountedref) {
      this.calenderStructure();
      this.loadState();
    }
    this.getClassList();
  }

  /**
   * Task detail popup
   *
   * @param taskId
   * @param $event
   */
  viewPopup(taskId: number, $event: any) {
    this.$emit('viewTaskDetail', { taskId: taskId });
    $event.stopPropagation();
  }
  calenderStructure() {
    let tempTaskList = JSON.parse(JSON.stringify(this.tasks));
    tempTaskList = tempTaskList.filter((task: ITaskDto) => task.status !== APP_CONST.TASK_STATUS_SKIP);
    let list: IEvents[] = [];
    tempTaskList.forEach((item: ITaskDto, index: number) => {
      let differenceinTime: number = new Date(item.taskStartDate).getTime() - new Date(item.taskEndDate).getTime();
      let differenceinDays: number = differenceinTime / (1000 * 3600 * 24);
      if ((differenceinDays >= 1) || (differenceinDays <= -1)) {
        list.push({
          data: { title: item.name, color: APP_CONST.STYLE.COLOR.NEUTRAL_900, class: `ltStrip ${this.checkOverDueTask(item) && 'overDueStrip'}`, elementId: `task_lt_${item.id}`, id: item.id },
          schedule: {
            month: [new Date(item.taskStartDate).getMonth()], duration: 30,
            durationUnit: 'minutes', times: [`00`], year: [new Date(item.taskStartDate).getFullYear()], dayOfMonth: [new Date(item.taskStartDate).getDate()]
          }
        });
        list.push({
          data: { title: item.name, color: APP_CONST.STYLE.COLOR.NEUTRAL_900, class: `rtStrip ${this.checkOverDueTask(item) && 'overDueStrip'}`, elementId: `task_lt_${item.id}`, id: item.id },
          schedule: {
            month: [new Date(item.taskEndDate).getMonth()], duration: 30,
            durationUnit: 'minutes', times: ["00"], year: [new Date(item.taskEndDate).getFullYear()], dayOfMonth: [new Date(item.taskEndDate).getDate()]
          }
        });
      } else {
        list.push({
          data: { title: item.name, color: APP_CONST.STYLE.COLOR.NEUTRAL_900, class: `ltStrip rtStrip ${this.checkOverDueTask(item) && 'overDueStrip'}`, elementId: `task_lt_${item.id}`, id: item.id },
          schedule: {
            month: [new Date(item.taskStartDate).getMonth()], duration: 30,
            durationUnit: 'minutes', times: ["00"], year: [new Date(item.taskStartDate).getFullYear()], dayOfMonth: [new Date(item.taskStartDate).getDate()]
          }
        });
      }
    })
    this.defaultEvents = JSON.parse(JSON.stringify(list));
  }

  loadState() {
    let state: { preferToday: boolean, events: IEvents[], eventsOutside: boolean, updateRows: boolean, fill: boolean, listTimes: boolean, updateColumns: boolean } = { preferToday: false, events: [], eventsOutside: true, updateRows: true, fill: true, listTimes: false, updateColumns: false };
    if (!state.events || !state.events.length) {
      state.events = this.defaultEvents;
      state.fill = this.view == 'months' ? true : false;
      state.listTimes = this.view == 'months' ? false : true;
      state.updateColumns = this.view == 'months' ? false : true;
    }

    this.$refs.cal && this.$refs.cal.setState(state);
    this.createTitleDate();
  }

  changeView(view: 'weeks' | 'months') {
    plannerStore.changeDurationView(view);
    let startMonthIndex = this.calendar.end.month;
    const around = startMonthIndex !== new Date().getMonth() ? ((view === 'months') ? this.calendar.end : this.calendar.start) : undefined
    this.calendar = Calendar[view]();
    const type: 'WEEK' | 'MONTH' = view === 'weeks' ? 'WEEK' : 'MONTH';
    this.calendar = Calendar.forType(Units[type], 1, around);
    setTimeout(() => {
      this.loadState();
      this.$emit('calenderView', view, this.customDate);
      this.getClassList();
    })
  }

  prev() {
    this.calendar.prev(1);
    this.createTitleDate();
    this.getClassList();
    this.$emit('calenderView', this.view, this.customDate);
  }

  getClassList() {
    setTimeout(() => {
      let arrlist: any = document.querySelector('.ds-ev-title') as HTMLElement;
      plannerStore.updateCalendarDataStatus(arrlist ? !!arrlist.innerText : false);
    }, 100)
  }

  next() {
    this.calendar.next(1);
    this.createTitleDate();
    this.getClassList();
    this.$emit('calenderView', this.view, this.customDate);
  }

  createTitleDate() {
    let inputcal = this.calendar;
    let endMonthIndex = inputcal && inputcal.end.month;
    let startMonthIndex = inputcal && inputcal.start.month;
    let startyear = inputcal && inputcal.start.year;
    let endyear = inputcal && inputcal.end.year;
    let endDayOfMonth = inputcal && inputcal.end.dayOfMonth;
    let startDayOfMonth = inputcal && inputcal.start.dayOfMonth;
    if (endDayOfMonth - startDayOfMonth > 25) {
      this.customDate = APP_CONST.MONTH_NAME[endMonthIndex] + ' ' + endyear;
    } else {
      if (document.documentElement.clientWidth < 375) {
        this.customDate = APP_CONST.MONTH_NAME[startMonthIndex].substring(0, 3);
      } else if (document.documentElement.clientWidth >= 375 && document.documentElement.clientWidth < 769 && APP_UTILITIES.checkMobile()) {
        if (startMonthIndex == endMonthIndex) {
          this.customDate = APP_CONST.MONTH_NAME[startMonthIndex].substring(0, 3) + ' ' + startDayOfMonth + '-' + endDayOfMonth;
        } else {
          this.customDate = APP_CONST.MONTH_NAME[startMonthIndex].substring(0, 3) + ' ' + startDayOfMonth + '-' + APP_CONST.MONTH_NAME[endMonthIndex].substring(0, 3) + ' ' + endDayOfMonth;
        }
      } else {
        if ((startyear == endyear) && ((startMonthIndex != endMonthIndex))) {
          this.customDate = APP_CONST.MONTH_NAME[startMonthIndex] + ' ' + startDayOfMonth + ' - ' + APP_CONST.MONTH_NAME[endMonthIndex] + ' ' + endDayOfMonth + ', ' + endyear;
        } else if ((startMonthIndex == endMonthIndex)) {
          this.customDate = APP_CONST.MONTH_NAME[startMonthIndex] + ' ' + startDayOfMonth + ' - ' + endDayOfMonth + ', ' + endyear;
        } else {
          this.customDate = APP_CONST.MONTH_NAME[startMonthIndex] + ' ' + startDayOfMonth + ', ' + startyear + '-' + APP_CONST.MONTH_NAME[endMonthIndex] + ' ' + endDayOfMonth + ', ' + endyear;
        }
      }
    }
  }

  checkOverDueTask(item: ITaskDto) {
    if ((this.convertDateInGMT(this.currentDate) > this.convertDateInGMT(String(item.taskEndDate))) && item.status === APP_CONST.TASK_STATUS_ACTIVE) {
      return true;
    }
    return false;
  }

  public convertDateInGMT(date: string) {
    return APP_UTILITIES.convertDateInGMT(date)
  }
  callHover($event: any) {
    let hoverPosition = { top: '0px', left: '0px' };
    const boundBox = $event.getBoundingClientRect();
    const coordX = boundBox.left;
    const coordY = boundBox.top;
    hoverPosition = {
      top: (coordY + 40).toString() + "px",
      left: (coordX + 5).toString() + "px"
    }
    return hoverPosition;
  }

  tooltip(elemId: string, title: string) {
    const element = document.getElementById(elemId);
    const elemToAppendTooltip = element && element.parentElement && element.parentElement.parentElement;
    const cords = this.callHover(elemToAppendTooltip);
    const node = document.createElement(`span`);
    node.id = `tooltip_${elemId}`;
    node.setAttribute('style', `top:${cords.top};left:${cords.left}`)
    node.setAttribute('class', 'tooltip-new');
    const textnode = document.createTextNode(`${title}`);
    node.appendChild(textnode);
    const elementdaySpan = document.getElementById('dayspan');
    elementdaySpan && (elementdaySpan.append(node));
  }

  removeTooltip(elemId: string) {
    const tooltipElem = document.getElementById(elemId);
    tooltipElem && (tooltipElem.remove());
  }

}